import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { Question } from "../entities/question";

export async function get() {
  const q = query(collection(db, "questions"));
  const querySnapshot = await getDocs(q);
  const questions: Question[] = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    const lang = "es";
    const question: Question = {
      question: data.question[lang],
      answerSelectionType: data.answerSelectionType,
      answers: data.answers[lang],
      correctAnswer: data.correctAnswer,
      explanation: data.explanation[lang],
    };
    questions.push(question);
  });
  return questions;
}
