import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import translationES from "./locales/es.json";

i18n.use(initReactI18next).init({
  resources: {
    es: {
      translation: translationES,
    },
  },
  lng: "es", // Default language
  fallbackLng: "es", // Fallback language if translation is missing
});

export default i18n;
