import { ReactNode } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

const IconImg = styled("img")({
  height: "24px",
});

type BaseProps = {
  children: ReactNode;
};

const Base = (props: BaseProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <IconImg
            src="/icon.svg"
            alt="Icon"
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {t("appTitle")}
          </Typography>
          <Button color="inherit">{t("loginButton")}</Button>
        </Toolbar>
      </AppBar>
      <Container>{props.children}</Container>
    </div>
  );
};

export default Base;
