import { get as apiGetQuestions } from "../apis/questions";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Question } from "../entities/question";

export interface QuizState {
  questions: Question[];
}

const initialState: QuizState = {
  questions: [],
};

export const getQuestions = createAsyncThunk("quiz/getQuestions", async () => {
  return await apiGetQuestions();
});

export const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getQuestions.fulfilled, (state, action) => {
      state.questions = action.payload;
    });
  },
});
