import { useSelector } from "react-redux";
import { Question } from "../entities/question";
import { RootState } from "../store";

import Quiz from "@paragliding-exam/react-quiz-component";

function getQuiz(questions: Question[]) {
  // Copying questions object to avoid issue with react-quiz-component
  // The array can't be read-only
  const quizQuestions: any[] = [];
  questions.forEach((question) => {
    const quizQuestion: any = Object.assign({}, question);
    quizQuestion.questionType = "text";
    quizQuestion.correctAnswer = quizQuestion.correctAnswer.toString();
    quizQuestions.push(quizQuestion);
  });
  return {
    quizTitle: "Quiz title",
    quizSynopsis: "Quiz Synopsis",
    questions: quizQuestions,
  };
}

export const QuizCard = () => {
  const questions: Question[] = useSelector(
    (state: RootState) => state.quiz.questions
  );

  if (questions.length > 0) {
    return <Quiz quiz={getQuiz(questions)} />;
  } else {
    return <span>Loading</span>;
  }
};
