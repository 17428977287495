import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { getQuestions } from "./slices/quiz";
import { store } from "./store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

store.dispatch(getQuestions());

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);
