import Base from "./components/Base";
import { QuizCard } from "./components/QuizCard";

function App() {
  return (
    <Base>
      <QuizCard />
    </Base>
  );
}

export default App;
